const week = {
    price: 149,
    identifier: 'week',
    interval: '7 days',
    fancyName: 'Недельный',
    currency: 'RUB',
    getFancyPrice() {
        return `${this.price} ₽`;
    },
};

const month = {
    price: 299,
    fancyName: 'Месячный',
    identifier: 'month',
    interval: '1 month',
    currency: 'RUB',
    getFancyPrice() {
        return `${this.price} ₽`;
    },
};

const year = {
    price: 1000,
    fancyName: 'Годовой',
    identifier: 'year',
    interval: '12 months',
    currency: 'RUB',
    getFancyPrice() {
        return `${this.price} ₽`;
    },
};


export const premiums = [week, month, year];
